/**
 * Centralised location for checkout generic data updates
 */
import { AttributeValue } from '@msdyn365-commerce/retail-proxy';

/**
 * Gets the CommercialName from a list of attributes - returns the product name if no commercial name attribute is present
 * @param attributes
 * @param productName
 * @param commercialStyleNameAttribute
 * @returns
 */
export const getCommercialName = (
    attributes: AttributeValue[] = [],
    productName: string = '',
    commercialStyleNameAttribute: string = 'CommercialStyleName'
): string => {
    var styleName = attributes.find(x => x.Name === commercialStyleNameAttribute);
    var productBrand = productName?.split(' ')[0] ?? '';
    productBrand = productBrand.toUpperCase();
    if (styleName?.TextValue) {
        return `${productBrand} ${styleName?.TextValue}`;
    }

    return productName;
};
